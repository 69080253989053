import { features } from './features'; 

features.applicationType = 'agrogest';

// FITOGEST
features.showAnalisis = true;
features.showPhCaldo = true;
features.showPartida = true;
features.puedeImprimir = true;
features.showRegistroTratamiento = true;
features.showEnviarCorreo = true;
features.showEnviarCorreoVisitas = false;
features.showTareasBio = false;
features.showMeteoBtn = true;
features.showVentusky = true;
features.showTratamientosAllProductos = true;
features.showVisitasCamposPersonalizados = true;

// AGROGEST
features.showAplicadoresFichajes = true;
features.showAplicadoresPartes = true;
features.showTrabajadoresCargos = true;
features.showTareasOtros = true;
features.showTareasSiembra = false;
features.showCostes = true;
features.showCostesOtros = true;
features.showCostesProductos = true;
features.showCostesTareasFertiCsv = false;
features.showCostesTareasOtrasCsv = true;
features.hasClients = false;
features.showStock = true;
features.showStockProveedor = false;
features.showStockEnvase = false;
features.showStockSemilla = true;
features.showSectorPlantas = false;
features.fertilizantesNombreRequired = true;
features.fertilizantesUdsMedidaRequired = true;
features.showVisitas = true;
features.showVisitasConsultas = true;
features.showHistoricoFT = true;
features.showRecetaGlobalGAP = true;
features.showExepcionales = false;
features.showOtrasDenominaciones = false;
features.showRecetaAgrogest = true;
features.showRecetaFertiAgrogest = true;
features.showStockReto = false;
features.showReto = true;
features.showStockTipoDeDato = true;
features.precioHora = true;
features.betweenDatesGenericas = true;

features.showTareasOtrosDuracion = false;
features.showTareasFitoHoras = true;
features.showTareasFertiHoras = true;
features.tareasHorasTotales = true;

features.timeStartStopTareas = true;


features.showMapaLabels = true;
features.showTareasSiembraProductos = false;
features.showProductosNumReg = true;

// BUSINESS INTELLIGENCE
features.showEstadisticas = false;
features.showUsuariosLogs = true;
features.showUsuariosFirma = true;

features.showSubcontrato = true;

export const environment = {
    production: true,
    v: '2',
    serverUrl: 'https://mbcpomelos.agrogest.com/',
    logoUrl: 'assets/logo_mbcpomelos.png',
    appName: 'mbcpomelos',
    features: features
};
